<template>
  <div class="home-page">
    <div  class="ActiveTitle" style="width: 70%; margin-left: auto; margin-right: auto;text-align: center;">全國閱讀好小子學藝競賽活動 - 新增參賽班級名單</div>
    
    <div style="width: 70%; margin-left: auto; margin-right: auto;">
      <el-card style="width: 100%" shadow="always" v-show="isLogin">
        <el-form label-position="left">
          <el-form-item label="參賽班級名稱">
            <el-input v-model="courseName" placeholder="輸入參賽班級名稱" style="width:300px" />
          </el-form-item>
        </el-form>
    
        <el-row>
          <el-col :span="5">
            請輸入學生，<br />
            格式: 名字,座號 <br />
            請直接從 excel 複製兩欄資料過來貼上<br />
            範例如下:<br />            
            <img src="./../../assets/img/2024091301.jpg" />
            <!-- 範例如下:<br />
            <div><span class="otheBlueColor stepName">
                John,1<br />
                Andy,2<br />
                Sam,3<br />
                May,4<br />
              </span></div> -->
    
    
          </el-col>
          <el-col :span="4">
            <el-input type="textarea" v-model="studentInput" placeholder="大雄	1
胖虎	2
小夫	3
靜香	4
聰明	5
" rows="20" />
          </el-col>
          <el-col :span="5" class="text-center">
            <el-button type="primary" @click="addStudents" style="margin-top:100px">
              新增學生
              <el-icon class="el-icon--right">
                <ArrowRight />
              </el-icon>
            </el-button>
          </el-col>
          <el-col :span="10">
            <el-table :data="students" border style="width: 100%" empty-text="...">
              <el-table-column prop="name" label="名字" />
              <el-table-column prop="seat" label="座號" />
              <!-- 添加删除按钮的操作列 -->
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button type="danger" size="small" @click="deleteStudent(scope.$index)">刪除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="text-center">
            <el-button type="primary" @click="submitCourse" style="margin-top:40px">送出</el-button>
          </el-col>
        </el-row>
    
    
      </el-card>
      <el-card style="width: 100%" shadow="always" v-show="!isLogin">
        <div style="width: 10%; margin-left: auto; margin-right: auto;">
        <el-button type="warning" @click="gologin" style="margin-top:20px;margin-bottom:20px;" size="large"  >
              請登入
              <el-icon class="el-icon--right">
                <ArrowRight />
              </el-icon>
            </el-button>
        </div>
      </el-card>
    </div>
   
 </div>
</template>
<script setup>
  import {ref,onMounted} from 'vue'
  import {ElMessage} from 'element-plus'
  import profile from "../../api/profile";
  import {Base64} from 'js-base64'  
  import api from "@/api";

  let isLogin= ref(false);  
  
  // 定义课程名称和学生列表
  const courseName = ref('')
  const studentInput = ref('')
  const students = ref([])
  // 添加學生方法
  const addStudents = () => {
    const lines = studentInput.value.split('\n')
    const newStudents = []
    for (const line of lines) {
      //const [name, seat] = line.split(',')
      if (line && line !== "") {


        // 每行按逗号或Tab字符分割，获得名字和座号
        const [name, seat] = line.split(/[\t,]/);

        // 座號重複檢查
        if (students.value.some(student => student.seat === seat.trim())) {
          ElMessage.error(`座號 ${seat.trim()} 已經存在！`)
          return
        }        
        newStudents.push({
          name: name,
          seat: seat
        })
      }
    }
    students.value.push(...newStudents)
    studentInput.value = ''
  }
  // 删除學生方法
  const deleteStudent = (index) => {
    students.value.splice(index, 1)
    ElMessage.success('學生已刪除')
  }
  // 送出課程資料
  const submitCourse = async () => {    
    if (!courseName.value || students.value.length === 0) {
      ElMessage.error('請輸入課程名稱並添加學生！')
      return
    }
    
    let members = [];
    students.value.forEach((item) =>{
      let mitem = {
        id: "",
        code: null,
        type: 3,
        nickname: item.name,
        no: item.seat,
        irs: item.seat
      }
      members.push(mitem);
    });
    let data={
      courseName: "HiTeach",
      groupListName: courseName.value,
      limitCount: 200,
      courseId: "",
      member: members,      
      };
        
    // 呼叫新增參賽班級名單API
    let JointCourseData = await api.main.setJointCourseUpsertPlus(data); 
    console.log(JSON.stringify(JointCourseData));
    if (JointCourseData) {
      
      ElMessage.success('新增參賽班級名單成功！')
    }
  }
  // 登入
  function gologin() {
     // 特殊頁面
      sessionStorage.setItem("info", "4");
      let urlhost = location.host == "localhost:5005" ? "testHT.teammodel.net" : location.host
      let data = {
        client_id: profile.clientID["global"],
        op_redirecturi: "https://" + urlhost + "/Binding_CheckAccount"
      }
      let datastr = encodeURIComponent(Base64.encode(JSON.stringify(data)));

      let url = "https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=https://account.teammodel.net/&scope=openid+email+profile+eduinfo&state=" + datastr + "&nonce=aaaaa";
      location.href = url;
    }

  onMounted(() => {
  //檢查登入
  if (!sessionStorage.getItem("id_token")) {
    isLogin.value = false;
    //router.push({ name: 'goodkid' }).then(() => { window.location.reload(); })    
  }else{
    isLogin.value = true;
  }      
})
</script>
<style scoped>
  .text-center {
    text-align: center;
  }
   .ActiveTitle{
      font-weight:bold;
      font-size: 40px;
      margin-bottom: 40px;      
    } 
    .el-table__body th, .el-table__body td {
  text-align: center;
  border: 2px solid #000; /* 深色边框 */
  color: #606266;
}
.stepName{
          font-size: 15px;
          font-weight:bold;
        }
        .grid-content {
  border-radius: 4px;
  min-height: 36px;
}
/* .el-table__body--border{
  border-color: #BEBEBE;
}
.el-table .cell {
  border-color: red;
}
.el-table--border{
  border-color: #BEBEBE;
}

.el-table th, .el-table td {
  border: 1px solid #333; 
}


.el-table th {
  background-color: #f5f5f5;
  border-bottom: 2px solid #333; 
}


.el-table td {
  border-color: #333; 
} */

</style>